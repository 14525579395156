import { createMuiTheme } from '@material-ui/core/styles';
import {
    MontserratLight,
    MontserratNormal,
    Montserrat500,
    MontserratBold,
    MontserratHeavy,
} from './montserrat';

const PRIMARY_COLOR = '#59c6ee';
const PRIMARY_DARK_COLOR = "#39aeef";
const WHITE_COLOR = '#fff';
const BLACK_COLOR = 'rgba(0,0,0,.87)';
const INACTIVE_COLOR = '#7e9bc4';
const BACKGROUND_COLOR = '#F2F7FF';
const DISABLED_COLOR = '#b2c4de';
const GRAY_COLOR = '#1d1d1d';
const GRAY_LIGHT_COLOR = '#969696';

export const theme = createMuiTheme({
    mixins: {
        section: {
            paddingLeft: '3em',
            marginBottom: '3.6em',
        }
    },
    props: {
        MuiAppBar: {
            elevation: 0,
            color: "transparent"
        },
        MuiPaper: {
            elevation: 0
        },
        MuiCard: {
            elevation: 0
        },
        MuiButton: {
            disableElevation: true
        },
    },
    palette: {
        text: {
            main: BLACK_COLOR,
        },
        highlight: {
            main: PRIMARY_COLOR,
        },
        disabled: {
            main: DISABLED_COLOR,
        },
        inactive: {
            main: INACTIVE_COLOR,
        },
        primary: {
            main: PRIMARY_COLOR,
            dark: PRIMARY_DARK_COLOR,
            contrastText: WHITE_COLOR
        },
        secondary: {
            main: WHITE_COLOR
        },
        background: {
            paper: 'inherit'
        },
        gray: {
            main: GRAY_COLOR,
            light: GRAY_LIGHT_COLOR,
        }
    },
    typography: {
        h1: {
            fontWeight: 'bold',
            fontSize: 80,
        },
        h2: { fontWeight: "bold" },
        h3: { fontWeight: "bold" },
        h4: { fontWeight: "900" },
        h5: { fontWeight: '900' },
        h6: { fontWeight: "bold" },
        subtitle1: {
            fontWeight: '500',
            fontSize: 13,
        },
        button: {
            fontWeight: 'bold'
        },
        overline: {
            fontWeight: 'bold',
            color: PRIMARY_COLOR,
        },
        fontFamily: [
            'Montserrat',
            'Arial',
            'sans-serif'
        ].join(',')
    },
    overrides: {
        MuiAppBar: {
            root: {
                marginBottom: 0,
            },
            colorTransparent: {
                color: WHITE_COLOR
            }
        },
        MuiButton: {
            root: {
                borderRadius: 5,
            },
            label: {
                textTransform: "none",
                fontWeight: '700'
            }
        },
        MuiCard: {
            root: {
                backgroundColor: WHITE_COLOR,
                borderRadius: 10
            }
        },
        MuiPaper: {
            root: {
                maxWidth: 1300,
                margin: '0 auto',
            },
        },
        MuiMenuItem: {
            root: {
                fontSize: 12,
                fontWeight: 500,
                paddingTop: 15,
                paddingBotttom: 15,
            },
        },
        MuiCssBaseline: {
            '@global': {
                '@font-face': [
                    MontserratLight,
                    MontserratNormal,
                    Montserrat500,
                    MontserratBold,
                    MontserratHeavy,
                ],
                html: {
                    width: '100%',
                    overflowX: 'hidden',
                },
                body: {
                    backgroundColor: BACKGROUND_COLOR,
                    width: '100%',
                    overflowX: 'hidden'
                }

            }
        },
        MuiAccordion: {
            root: {
                '&:before': {
                    height: 0,
                }
            }
        },
        MuiAccordionSummary: {
            root: {
                padding: 0,
            }
        }
    },
    shape: {
        borderRadius: 10
    }
});