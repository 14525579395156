import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

export default withStyles(theme=>({
    root: {
        height: "52px",
        width: "210px",
        padding: "16px 48px 16px 48px",
    }
}))(({children, classes, ...props})=>{
    return (
        <Button variant="contained" color="primary" classes={classes} {...props}>
            <Typography variant="button">{children}</Typography>
        </Button>
    )
});