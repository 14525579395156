import { useState, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Popper from '@material-ui/core/Popper';
import Card from '@material-ui/core/Card';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LanguageIcon from '@material-ui/icons/Language';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ReactComponent as SearchIcon } from '../../ic_search.svg';
import { ReactComponent as ShopIcon } from '../../ic_cart.svg';
import { ReactComponent as ArrowIcon } from '../../ic_arrow.svg';
import { ReactComponent as EasychangoLogo } from '../../logo-black.svg';
import { useTranslation } from "react-i18next";
import { menuItems } from '../../data';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        '& svg.icon path': {
            stroke: theme.palette.text.main
        },
        '&.light svg.icon path': {
            stroke: 'white'
        },
        '&.light *': {
            color: 'white',
            borderColor: '#ffffff70'
        }
    },
    appBar: {
        top: '10px',
        right: 0,
        left: 0,
        ...theme.mixins.section,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    logo: {
        minWidth: 200,
        [theme.breakpoints.down('sm')]: {
            '& path': { fill: 'white' }
        }
    },
    menu: {
        borderRadius: 5
    },
    menuLink: {
        textDecoration: 'none',
        color: 'inherit'
    },
    menuLinkActive: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    mobileMenu: {
        position: 'relative',
        backgroundColor: '#fffe',
        height: '100%',
        width: '100%',
        borderRadius: 0,
        padding: theme.spacing(3),
        overflow: 'scroll'
    },
}));

export default function Header({ light = false, menus = menuItems }) {
    const { t } = useTranslation();
    const classes = useStyles();

    const divingRef = useRef(null);
    const [divingOpen, setDivingOpen] = useState(false);
    const handleToggle = () => setDivingOpen(!divingOpen);
    const handleClose = () => setDivingOpen(false);

    const coursesRef = useRef(null);
    const [coursesOpen, setCoursesOpen] = useState(false);
    const handleCoursesToggle = () => setCoursesOpen(!coursesOpen);
    const handleCoursesClose = () => setCoursesOpen(false);


    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const handleMobileMenuOpen = () => setShowMobileMenu(true);
    const handleMobileMenuClose = () => setShowMobileMenu(false);

    const location = useLocation();
    console.log(location);

    return (
        <AppBar id="top" position="absolute" classes={{ root: classes.appBar }}>
            <Toolbar classes={{ root: classes.toolbar }} className={light ? "light" : ""} disableGutters>
                <Hidden mdUp>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item classes={{ root: classes.logo }}>
                            <EasychangoLogo style={{ color: 'white', }} />
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="menu" onClick={handleMobileMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden smDown>
                    <Grid container justify="flex-end" alignItems="center" wrap="nowrap" >
                        <Grid item classes={{ root: classes.logo }} xs={2}>
                            <EasychangoLogo />
                        </Grid>

                        <Grid item xs={7} container wrap="nowrap" alignItems="center">
                            <Grid item xs={3}>
                                <ButtonGroup variant='text'>
                                    {menus.language.items.map(({ label }) => (
                                        <Button key={label}>
                                            <Typography>{label}</Typography>
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    ref={divingRef}
                                    endIcon={<ArrowIcon className="icon"/>}
                                    aria-controls={divingOpen ? "menu-list-grow" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                >
                                    <Typography>{t("Diving Tours")}</Typography>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    ref={coursesRef}
                                    endIcon={<ArrowIcon className="icon" />}
                                    aria-controls={coursesOpen ? "menu-list-grow" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleCoursesToggle}
                                >
                                    <Typography>{t("Courses Title")}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item container xs={3} alignItems="center" justify="space-around" >
                            <Grid item >
                                <IconButton>
                                    <ShopIcon className="icon"/>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton>
                                    <SearchIcon className="icon" />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined">Book Now</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
            </Toolbar>

            <Popper open={divingOpen} anchorEl={divingRef.current} transition disablePortal placement="bottom-start">
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'top' }}>
                        <Card classes={{ root: classes.menu }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    {menus.divingTours.items.map(
                                        ({ label, path }) => (
                                            <MenuItem key={label}>
                                                <Link
                                                    to={path}
                                                    className={classNames(
                                                        classes.menuLink,
                                                        { [classes.menuLinkActive]: location.pathname === path }
                                                    )}>
                                                    <span dangerouslySetInnerHTML={{ __html: t(label) }} />
                                                </Link>
                                            </MenuItem>)
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Card>
                    </Grow>
                )}
            </Popper>
            <Popper open={coursesOpen} anchorEl={coursesRef.current} transition disablePortal placement="bottom-start">
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'top' }}>
                        <Card classes={{ root: classes.menu }}>
                            <ClickAwayListener onClickAway={handleCoursesClose}>
                                <MenuList>
                                    {menus.courses.items.map(
                                        ({ label }) => <MenuItem key={label}><span dangerouslySetInnerHTML={{ __html: t(label) }} /></MenuItem>)}
                                </MenuList>
                            </ClickAwayListener>
                        </Card>
                    </Grow>
                )}
            </Popper>

            <Dialog fullScreen open={showMobileMenu} onClose={handleMobileMenuClose}>
                <Paper classes={{ root: classes.mobileMenu }}>
                    <Grid container direction="column" style={{ height: '100%' }} spacing={4} wrap="nowrap">
                        <Grid container item direction="row" justify="space-between" >
                            <EasychangoLogo />
                            <IconButton onClick={handleMobileMenuClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Accordion square>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-conttrols="divingtour-content"
                                    id="divingtour-content"
                                >
                                    <Typography variant="h4">{t("Diving Tours")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction="column" spacing={2}>
                                        {menus.divingTours.items.map(
                                            ({ label, path }) => (
                                                <Grid item key={label}>
                                                    <Link
                                                        to={path}
                                                        onClick={handleMobileMenuClose}
                                                        className={classNames(
                                                            classes.menuLink,
                                                            { [classes.menuLinkActive]: location.pathname === path }
                                                        )}>
                                                        <span dangerouslySetInnerHTML={{ __html: t(label) }} />
                                                    </Link>
                                                </Grid>)
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item>
                            <Accordion square>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-conttrols="courses-content"
                                    id="courses-content"
                                >
                                    <Typography variant="h4">{t("Courses Tours")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container direction="column" spacing={2}>
                                        {menus.courses.items.map(
                                            ({ label }) => <Grid item key={label}><span dangerouslySetInnerHTML={{ __html: t(label) }} /></Grid>)}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">{t("Cart Title")}</Typography></Grid>
                        <Grid item style={{ marginTop: 'auto' }}>
                            <Typography variant="h4">{t("Language Title")}</Typography>
                            <Grid container style={{ alignItems: 'center' }}>
                                <Grid item>
                                    <LanguageIcon />
                                </Grid>
                                <Grid item>
                                    <ButtonGroup variant='text'>
                                        {menus.language.items.map(({ label }) => (
                                            <Button key={label}>
                                                <Typography>{label}</Typography>
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Dialog>
        </AppBar>
    );
}