import MontserratRegularWoff2 from './fonts/montserrat-v15-latin-regular.woff2';
import MontserratRegularWoff from './fonts/montserrat-v15-latin-regular.woff';
import Montserrat500Woff2 from './fonts/montserrat-v15-latin-500.woff2';
import Montserrat500Woff from './fonts/montserrat-v15-latin-500.woff';
import MontserratBoldWoff2 from './fonts/montserrat-v15-latin-700.woff2';
import MontserratBoldWoff from './fonts/montserrat-v15-latin-700.woff';
import MontserratBolderWoff2 from './fonts/montserrat-v15-latin-900.woff2';
import MontserratBolderWoff from './fonts/montserrat-v15-latin-900.woff';
import MontserratLightWoff2 from './fonts/montserrat-v15-latin-200.woff2';
import MontserratLightWoff from './fonts/montserrat-v15-latin-200.woff';

export const MontserratNormal = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `
        local('Montserrat'),
        local('Montserrat-Regular'),
        url(${MontserratRegularWoff2}) format('woff2'),
        url(${MontserratRegularWoff}) format('woff')
    `
}
export const Montserrat500 = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    src: `
        local('Montserrat'),
        local('Montserrat-Regular'),
        url(${Montserrat500Woff2}) format('woff2'),
        url(${Montserrat500Woff}) format('woff')
    `
}

export const MontserratBold = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,
    src: `
        local('Montserrat'),
        local('Montserrat-Bold'),
        url(${MontserratBoldWoff2}) format('woff2'),
        url(${MontserratBoldWoff}) format('woff')
    `
}

export const MontserratHeavy = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 900,
    src: `
        local('Montserrat'),
        local('Montserrat-Bolder'),
        url(${MontserratBolderWoff2}) format('woff2'),
        url(${MontserratBolderWoff}) format('woff')
    `
}

export const MontserratLight = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 200,
    src: `
        local('Montserrat'),
        local('Montserrat-Light'),
        url(${MontserratLightWoff2}) format('woff2'),
        url(${MontserratLightWoff}) format('woff')
    `
}