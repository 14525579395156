import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Link from "@material-ui/core/Link";
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ActionButton from '../ActionButton';
import { ReactComponent as Logo } from '../../logo-black.svg';

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            marginTop: theme.spacing(5),
            ...theme.mixins.section,
            '& .MuiTypography-overline': {
                [theme.breakpoints.down('sm')]: {
                    fontSize: 18,
                }
            }
        },
        buttonItem: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
        button: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1),
            boxShadow: '0 0 20px 0 #9bb8cd85',
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            }
        },
        logo: {
            width: 100,
            height: 100,
            [theme.breakpoints.down('xs')]: {
                position: 'absolute',
                top: 0,
                left: theme.spacing(-10),
                '& path': { fill: theme.palette.disabled.main }
            }
        },
        locationText: {
            color: theme.palette.disabled.main,
            fontWeight: "900"
        },
        tulumEmblemContainer: {
            paddingBottom: theme.spacing(7),
            [theme.breakpoints.down('xs')]: {
                paddingBottom: theme.spacing(0),
                marginLeft: '-8em',
                transform: 'scale(.7)'
            }
        },
        popup: {
            position: 'absolute',
            overflow: "inherit",
            top: 0,
            left: 100,
            padding: theme.spacing(1),
            boxShadow: '0 0 20px 0 #9bb8cd85',
            '&:before': {
                position: 'absolute',
                content: '""',
                height: theme.spacing(2),
                width: theme.spacing(2),
                backgroundColor: theme.palette.background.paper,
                bottom: `-${theme.spacing(1)}px`,
                left: `calc(50% - ${theme.spacing(1)}px)`,
                transform: 'rotate(45deg)',
            },
        },
        mapContainer: {
            position: 'relative',
            [theme.breakpoints.down('xs')]: {
                marginLeft: '-5em'
            }
        },
        icon: {
            color: theme.palette.disabled.main
        },
        texture: {
            marginTop: theme.spacing(3),
            position: 'absolute',
            left: theme.spacing(-12),
        },
    })
);

export default function Contact() {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Paper classes={{ root: classes.container }}>
            <Grid container alignItems={smDown ? "flex-start" : "center"} direction={smDown ? 'column' : 'row'}>
                <Grid item container xs={12} md={5} justify="space-between">
                    <Grid item container direction="column" justify="flex-start" spacing={1} xs={6}>
                        <Grid item>
                            <Typography variant="overline">{t("contact")}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">{t("Contact Title")}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1">{t("Contact subtitle")}</Typography>
                        </Grid>
                        <Grid item classes={{ root: classes.buttonItem }}>
                            <Link href={t("whatsappLink")} target="_blank"><ActionButton>{t("ButtonContact")}</ActionButton></Link>
                        </Grid>
                        <Hidden smDown>
                            <Grid item style={{ position: 'relative' }}>
                                <img alt="" className={classes.texture} src="/images/texture.svg" />
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item xs={6} container alignItems="center" direction="column">
                            <img alt="easychango logo" className={classes.logo} src="/images/logo_blue.svg" />
                        </Grid>
                    </Hidden>
                </Grid>

                <Grid item container xs={12} sm={12} md={6} alignItems="flex-end" wrap="nowrap" style={{ position: 'relative' }}>
                    <Hidden smUp>
                        <Grid item>
                            <Logo className={classes.logo} />
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={6} classes={{ root: classes.mapContainer }}>
                        <figure className={classes.figure}>
                            <img alt="map of the state of Quintana Roo" src="/images/map_tulum.svg" />
                            <Card classes={{ root: classes.popup }}>
                                <CardContent>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="h6">Tulum</Typography>
                                        </Grid>
                                        <Grid item container wrap="nowrap" spacing={1}>
                                            <Grid item>
                                                <MailOutlineIcon classes={{ root: classes.icon }} />
                                            </Grid>
                                            <Grid item>
                                                <Typography>{t("email")}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container wrap="nowrap" spacing={1}>
                                            <Grid item>
                                                <PhoneIphoneIcon classes={{ root: classes.icon }} />
                                            </Grid>
                                            <Grid item>
                                                <Typography>{t("phoneNumber")}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </figure>
                    </Grid>
                    <Grid
                        classes={{ root: classes.tulumEmblemContainer }}
                        item
                        container
                        xs={6}
                        alignItems="flex-end"
                        justify={smDown ? "space-around" : "space-between"}>
                        <Grid item container direction="column" xs={6}>
                            <Grid item>
                                <img alt="Tulum coat of arms" src="/images/tulum.svg" />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" classes={{ root: classes.locationText }}>Tulum, Quintana&nbsp;Roo</Typography>
                            </Grid>
                        </Grid>
                        <Hidden smDown>
                            <Grid item>
                                <IconButton
                                    size="medium"
                                    classes={{ root: classes.button }}>
                                    <WhatsAppIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>
        </Paper >
    );
}