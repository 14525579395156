export const explore = [
    {
        label: 'Bull Shark Title',
        imageUrl: '/images/bullshark-3 1.png',
        videoUrl: '/videos/bullshark.mp4',
        icon: {
            active: "/images/bullshark_active.svg",
            inactive: "/images/ic_bullshark_inactive.svg",
        }
    },
    {
        label: 'Ocean Diving Title',
        imageUrl: '/images/image 110.png',
        videoUrl: '/videos/oceandive.mp4',
        icon: {
            active: "/images/ocean_active.svg",
            inactive: "/images/ic_ocean_inactive.svg"
        }
    },
    {
        label: 'Cenote Diving Title',
        videoUrl: '/videos/cocodrilo.mp4',
        imageUrl: '/images/image 89.png',
        icon: {
            active: "/images/cave_active.svg",
            inactive: "/images/ic_cave_inactive.svg"
        }
    }
];

export const courses = [
    {
        title: "Open Water Diver Title",
        imageURL: "/images/openWater.png"
    },
    {
        title: "Advanced Open Water Diver Title",
        imageURL: "/images/advanced.png"
    },
    {
        title: "Rescue Diver Title",
        imageURL: "/images/rescue.png"
    },
    {
        title: "Dive Master Title",
        imageURL: "/images/divemaster.png"
    },
    {
        title: "Technical Scuba Diving Title",
        imageURL: "/images/technical.png"
    },
    {
        title: "Full Cave Scuba Diving Title",
        imageURL: "/images/fullcave.png"
    }
]

export const tours = [
    {
        title: "Cave Diving Title",
        text: "Cave Diving Text",
        image: "/images/cenoteCave.jpeg"
    },
    {
        title: "Whale Shark Title",
        text: "Whale Shark Text",
        image: "/images/whale.jpeg"
    },
    {
        title: "Bull Shark Title",
        text: "Bull Shark Text",
        image: "/images/bullshark-3 1.png"
    },
    {
        title: "Coral Reef Title",
        text: "Coral Reef Text",
        image: "/images/coralReef.jpeg"
    },
    {
        title: "Cenote Snorkeling Title",
        text: "Cenote Snorkeling Text",
        image: "/images/cenoteSnorkeling.png"
    },
    {
        title: "Deep Dive Title",
        text: "Deep Dive Text",
        image: "/images/deepDive.png"
    },
    {
        title: "Cavern Diving Title",
        text: "Cavern Diving Text",
        image: "/images/cavern.png"
    },
    {
        title: "Discover Scuba Diving Title",
        text: "Discover Scuba Diving Text",
        image: "/images/discoverScuba.png"
    }
];


export const menuItems = {
    divingTours: {
        label: "Diving Tours",
        items: [
            { label: "Bull Shark Title", path: "/bullshark" },
            { label: "Coral Reef Title", path: "/coral_diving" },
            { label: "Cavern Diving Title", path: "/cavern_diving" },
            { label: "Cave Diving Title", path: "/cave_diving" },
            { label: "Whale Shark Title" },
            { label: "Cenote Snorkeling Title" },
            { label: "Deep Dive Title" },
            { label: "Discover Scuba Diving Title" },
        ]
    },
    courses: {
        label: "Courses Title",
        items: [
            { label: "Discover Scuba Diving Title" },
            { label: "Open Water Diver Title" },
            { label: "Advanced Open Water Diver Title" },
            { label: "Rescue Diver Title" },
            { label: "EFR Title" },
            { label: "Dive Master Tile" },
            { label: "T.D.I. Courses Title" },
            { label: "Technical Diving Title" },
        ]
    },
    Cart: {
        label: "Cart Title",

    },
    language: {
        items: [{ label: "EN" }, { label: "ES" }]
    }
}