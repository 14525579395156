import { makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import Hidden from '@material-ui/core/Hidden';
import WhatsupIcon from '@material-ui/icons/WhatsApp';
import { useTranslation } from "react-i18next";
import { courses } from "../../data";
import Course from "../Course/index.js";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      margin: `${theme.spacing(5)}px 0`
    },
    paperRoot: {
      ...theme.mixins.section
    },
    extras: {
      marginTop: theme.spacing(6),
      '& > .MuiGrid-item': {
        paddingBottom: theme.spacing(2),
      },
      '& .MuiTypography-subtitle2': {
        color: theme.palette.inactive.main,
        fontSize: 12,
      },
      '& .MuiLink-root, & .MuiChip-root': {
        color: theme.palette.inactive.main,

      }
    },
    icon: {
      fill: theme.palette.primary.main,
    },
    gutter: {
      marginRight: '3em',
      borderTop: '1px solid #dae5f4',
    },
    carousel: {
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "auto",
      borderRadius: 0,
    }
  })
);

export default function Courses() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography classes={{ root: classes.title }} variant="h5">{t("Courses Title")}</Typography>
      </Paper>
      <Paper classes={{ root: classes.carousel }}>
        {courses.map((course, key) => (
          <Course key={key} {...course} />
        ))}
      </Paper>
      <Paper classes={{ root: classes.paperRoot }}>
        <Grid container classes={{ root: classes.extras }} alignItems="center">
          <Grid item xs={12} sm={4} md={2}>
            <Typography variant="h6">{t("We also have")}</Typography>
          </Grid>
          <Hidden smDown>
            <Grid item container xs={3} spacing={3}>
              <Grid item><Typography variant="subtitle2">{t("Snorkel Tours")}</Typography></Grid>
              <Grid item><Typography variant="subtitle2">{t("Fill Tank")}</Typography></Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item container xs={11} sm={7} spacing={3}>
              <Grid item> <Chip color="secondary" label={t("Snorkel Tours")} /></Grid>
              <Grid item> <Chip color="secondary" label={t("Fill Tank")} /></Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className={classes.gutter} />
            </Grid>
          </Hidden>
          <Grid item container alignItems="center" spacing={1} xs={12} md={7}>
            <Grid item xs={12} md={8}><Typography variant="subtitle2">{t("callUsDetails")}</Typography></Grid>
            <Grid item><WhatsupIcon classes={{ root: classes.icon }} /></Grid>
            <Grid item><Link href={t("whatsappLink")}> {t("phoneNumber")}</Link></Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
