import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) =>
    createStyles({
        divingToursImage: {
            borderRadius: theme.shape.borderRadius,
            height: 490,
            width: 490,
            objectFit: 'cover',
            [theme.breakpoints.down('sm')]: {
                zIndex: 100,
                height: 250,
                width: 250,
                position: 'relative',
                left: 20,
            }
        },
        divingTourcontainer: {
            marginRight: theme.spacing(35),
            position: "relative",
            [theme.breakpoints.up('md')]: {
                '&:first-child': { marginLeft: '3em', },
            },
            [theme.breakpoints.down('sm')]: {
                marginRight: theme.spacing(2),
                marginLeft: theme.spacing(2),
            }
        },
        blueLine: {
            borderTop: `3px solid ${theme.palette.highlight.main}`,
            width: theme.spacing(6),
            margin: `${theme.spacing(2)}px 0`,
        },
        card: {
            position: 'absolute',
            top: theme.spacing(6),
            right: '-40%',
            padding: theme.spacing(6),
            [theme.breakpoints.down('sm')]: {
                zIndex: 0,
                position: 'relative',
                right: 0,
                top: theme.spacing(-8),
                padding: theme.spacing(3),
                paddingTop: theme.spacing(9),
                paddingBottom: theme.spacing(0),
            }
        },
        cardContent: {
            margin: '0 auto',
            padding: 0,
            width: theme.spacing(35),
            height: theme.spacing(35),
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('lg')]: {
                width: theme.spacing(30),
                height: theme.spacing(35),
            },
            [theme.breakpoints.down('sm')]: {
                width: theme.spacing(30),
                height: theme.spacing(30),
            }
        },
        cardText: {
            lineClamp: 5,
            display: '-webkit-box',
            overflow: 'hidden',
            '-webkit-box-orient': 'vertical',
            textOverflow: 'ellipsis',
            marginBottom: theme.spacing(2),
            flex: '1 1',
        },
        exploreNow: {
            textTransform: 'none',
        },
    })
);

export default function DivingTour(tour) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className={classes.divingTourcontainer}>
            <img src={tour.image} className={classes.divingToursImage} />
            <Card classes={{ root: classes.card }}>
                <CardContent classes={{ root: classes.cardContent }}>
                    <Typography variant={smDown ? "h5" : "h4"} classes={{ root: classes.title }}>{t(tour.title)}</Typography>
                    <hr className={classes.blueLine} />
                    <Typography classes={{ root: classes.cardText }}>{t(tour.text)}</Typography>
                    <Typography classes={{ root: classes.exploreNow }} variant="overline">{t("Explore Now")}</Typography>

                </CardContent>
            </Card>
        </div>
    )
}