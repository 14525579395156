import DivingTours from '../DivingTours';
import AboutUs from '../AboutUs';
import Courses from '../Courses';
import Explore from '../Explore';
import Contact from '../Contact';
import Header from '../Header';
import Footer from '../Footer';

export default function LandingPage() {
    return (
        <>
            <Header light/>
            <Explore />
            <DivingTours />
            <Courses />
            <AboutUs />
            <Contact />
            <Footer /> 
        </>
    )
}