import { useTranslation } from "react-i18next";
import { Typography } from '@material-ui/core';
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) =>
    createStyles({
        link: {
            textDecoration: 'none',
            color: theme.palette.inactive.main
        },
        'underline': {
            paddingBottom: '.4em',
            marginBottom: '.4em',
            display: 'inline-block',
            borderBottom: '2px solid',
            borderBottomColor: theme.palette.inactive.main
        },
        active: {
            color: theme.palette.text.main,
            '& .imgWrapper': {
                borderBottomColor: theme.palette.highlight.main
            }
        }
    })
);

export default function GlyphLink({ onClick, src, label, active = false }) {
    const { t } = useTranslation();
    const classes = useStyle();
 
    return (
        <div className={`${classes.link} ${active ? classes.active : ''}`} onClick={onClick}>
            <div className={`imgWrapper ${classes.underline}`}>
                <img alt=""
                    src={src}
                    height="24px"
                    width="24px"
                />
            </div>
            <Typography variant="subtitle1" noWrap>{t(label)}</Typography>
        </div>
    )
}