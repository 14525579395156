import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Link from "@material-ui/core/Link";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { ReactComponent as EasyChangoLogo } from '../../logo-black.svg';
import { ReactComponent as FacebookLogo } from '../../ic_face.svg';
import { ReactComponent as TwitterLogo } from '../../ic_twitter.svg';
import { ReactComponent as InstagramLogo } from '../../ic_instagram.svg';
import { ReactComponent as ToTopArrow } from '../../ic_to_top.svg';
import { useTranslation } from "react-i18next";

const Header = withStyles(theme => ({
    root: {
        color: theme.palette.gray.main,
        fontWeight: 'bold',
        fontSize: 12,
        paddingBottom: theme.spacing(4)
    }
}))(Typography);

const Item = withStyles(theme => ({
    root: {
        color: theme.palette.gray.light,
        fontWeight: 'light',
        fontSize: 12,
        paddingBottom: theme.spacing(2)
    }
}))(Typography)

const useStyles = makeStyles(theme => createStyles({
    paperRoot: {
        ...theme.mixins.section,
        marginTop: '5em',
        [theme.breakpoints.down('xs')]: {
            color: theme.palette.inactive.main,
            '& svg path': {
                fill: theme.palette.inactive.main,
            }
        }
    },
    follow: {
        color: theme.palette.gray.light,
    },
    copyright: {
        ...theme.mixins.section,
        background: 'white',
        borderRadius: 0,
        marginBottom: 0,
        padding: 20,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        }
    },
    texture: {
        width: "100%"
    }
}));

export default function Footer() {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

    const handleBackToTop = event => {
        const anchor = (event.target.ownerDocument || document).querySelector('#top');
        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <>
            <Paper classes={{ root: classes.paperRoot }}>
                <Grid container alignItems={xsDown ? "center" : "flex-start"}>
                    <Hidden xsDown>
                        <Grid item container direction="column" xs={3} spacing={3}>
                            <Grid item><EasyChangoLogo /></Grid>

                            <Grid item><Typography classes={{ root: classes.follow }}>{t("Footer Follow Us")}</Typography></Grid>
                            <Grid item container spacing={1}>
                                <Grid item>
                                    <Link href={t("facebookLink")} target="_blank">
                                        <FacebookLogo />
                                    </Link>
                                </Grid>
                                <Grid item><TwitterLogo /></Grid>
                                <Grid item><InstagramLogo /></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Header>{t("Footer Diving Tours")}</Header>
                            <Item>Bull Shark Diving</Item>
                            <Item>Coral Reef Dive</Item>
                            <Item>Cenote Cave Dive</Item>
                            <Item>Cenote Cavern Dive</Item>

                        </Grid>
                        <Grid item xs={3}>
                            <Header>{t("Footer Courses")}</Header>
                            <Item>Discover Scuba Diving</Item>
                            <Item>Open Water Diver</Item>
                            <Item>Rescue Diver</Item>
                            <Item>Advanced Open Water Diver</Item>

                        </Grid>
                        <Grid item xs={3}>
                            <Header>{t("Footer Tank Filling")}</Header>
                            <Item>{t("Footer Filling Prices")}</Item>
                        </Grid>
                        {/* <Grid item style={{ paddingLeft: 116, textAlign: 'center' }}>
                    <div style={{ paddingBottom: 26 }}>Review Us On</div>
                    <div>
                        <img src="/images/tripadvisor.png" width="162" alt="Trip Advisor Logo"></img>
                    </div>
                </Grid> */}
                    </Hidden>
                    <Hidden smUp>
                        <Grid item container xs={3}>
                            <Grid item><EasyChangoLogo /></Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <img alt="" src="/images/texture.svg" className={classes.texture} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" paragraph>{t("contact")}</Typography>
                        </Grid>
                        <Grid item container>
                            <Grid item container xs={7} direction="column" spacing={2} style={{ display: 'block' }}>
                                <Grid item xs={12}><Typography>{t("phoneNumber")}</Typography></Grid>
                                <Grid item xs={12}><Typography>{t("diveEmail")}</Typography></Grid>
                                <Grid item xs={12}><Typography>{t("address")}</Typography></Grid>
                            </Grid>
                            <Grid item container xs={5} direction="column" spacing={2} alignItems='flex-end'>
                                <Grid item><Typography variant="subtitle2">{t("followUs")}</Typography></Grid>
                                <Grid item container justify="flex-end">
                                    <Grid item xs={3}>
                                        <Link href={t("facebookLink")} target="_blank">
                                            <FacebookLogo />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={3}><TwitterLogo /></Grid>
                                    <Grid item xs={1}><InstagramLogo /></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </Paper>
            <Paper classes={{ root: classes.copyright }}>
                <Grid container alignItems="center" justify="space-between" >
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2"> &copy; 2008-2021 Easy Chango</Typography>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item>
                            <Button
                                onClick={handleBackToTop}
                                endIcon={<ToTopArrow />}><Typography>Back to Top</Typography></Button>
                        </Grid>
                    </Hidden>
                </Grid>
            </Paper>
        </>
    )
}