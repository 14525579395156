import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const getNavigatorLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : // @ts-ignore
    navigator.userLanguage ||
    navigator.language ||
    // @ts-ignore
    navigator.browserLanguage ||
    "en";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      // Contact Info
      email: "buceo@easychango.com",
      diveEmail: "buceo@easychango.com",
      phoneNumber: "+(52) 984-144-8170",
      whatsappLink: "https://wa.me/5219841448170",
      facebookLink: "https://www.facebook.com/easychango",
      iatdLink: "https://iantd.com/index.php/en-us/",
      padiLink: "https://www.padi.com/",
      tdiLink: "https://www.tdisdi.com/",
      protecLink: "https://www.protecdive.com/",
      nacdLink: "http://www.nacdmembers.com/index.html",
      astdLink: "http://www.astd.com.pl/english.html",
      address: "Tulum, Tulum, 1235 Mexico",
      callUsDetails: "Give us a call / message to get more details!",
      followUs: "Follow Us",
      contact: "Contact",
      // Navigation
      backHome: "Back Home",


      // Landing page sections
      "Explore Title": "Explore",
      "Explore subtitle": "Live one of the best underwater experiences.",
      "Diving Tours": "Diving Tours",
      "Courses Title": "Courses",
      "Icons Title": "Partner Institutions",

      "Contact Title": "Get in touch with us",
      "Contact subtitle": "And live one of the best underwater experiences in Tulum",
      "Cart Title": "Cart",
      "Add to cart": "Add to cart",
      "Language Title": "Language",
      // buttons
      ButtonBook: "Book Now",
      ButtonRead: "Read More",
      ButtonContact: "Contact Us",

      // explore
      "Bull Shark Title": "Bull Shark",
      "Bull Shark Diving": "Bull Shark Diving",
      "Ocean Diving Title": "Night Diving",
      "Cenote Diving Title": "Cenote Diving",
      "Explore Now": "Explore Now",

      // Tours
      "Snorkel Tours": "Gear Rental",
      "Fill Tank": "Fill Station",

      "Diving Tour Blue Subtitle": "Explore now",
      "Cave Diving Title": "Cave Diving",
      "Cave Diving Text":
        "Yucatan peninsula is the mecca of Cave diving!  They are formed by precipitations enriched by carbon dioxide, release from vegetation, create by desolvation underground caves, also call locally as underground rivers or underwater cave systems. On Yucatan, especially around Tulum, you can find the largest underwater cave systems, thanks to this you have dive sites which fit you perfectly.",
      "Bull Shark Text":
        "From November through March, pregnant female Bull Sharks come near to Playa del Carmen, giving divers a great opportunity to meet them, the female bull sharks are calm and they let us get close without the need of any special protection.",

      "Ocean Diving Text":
        "We will take you on a boat trip to explore the riviera Maya, the second largest coral barrier reef in the world",
      "Cavern Diving Title": "Cavern Diving",
      "Cavern Diving Text":
        "In this type of diving you remain at a distance in which you always have access to natural light, which will allow you to glance at gorgeous turquoise blue and green colors.",
      "Deep Dive Title": "Deep Dive",
      "Deep Dive Text":
        "Around Tulum there are two sink holes which allow you to do deep dives, 'The Pit' and 'Angelita',there you can see two different chemical factor for example the haloclines, when fresh water meets salt water, since they cannot mix as you pass from one to the other one it produces a visual effect that blurs your vision temporarily.",
      "Coral Reef Title": "Coral Reef Diving",
      "Coral Reef Text":
        "The Riviera Maya is the second longest barrier coral reef from north of Cancún all the way down to Honduras. Depending on your diving skills and certification level, we can do deeper dives to search for turtles and reef sharks, or go on shallow dive in beautiful coral formations. Your divemaster takes you from the dive shop to the near coast and from there with a small boat to the dive spots where you do the dive.",
      "Whale Shark Title": "Whale Shark",
      "Whale Shark Text":
        "Whale Sharks come to the Riviera Maya between Isla Mujeres and Holbox",
      "Cenote Snorkeling Title": "Cenote Snorkeling",
      "Cenote Snorkeling Text":
        "Cenotes are the entrance to the worlds biggest underground river system. We swim in its sacred water",
      "Discover Scuba Diving Title": "Discover Scuba Diving",
      "Discover Scuba Diving Text": "In this program we help you to enjoy your first dive safely. At our diveshop you will get ready with all SCUBA equipment (self-contained underwater breathing apparatus). Your divemaster/instructor will give you a basic explication of proper usage of the gear and important skills to make diving fun and safe. So we ask you to keep attention for around half an hour.",
      "Technical Scuba Diving Title": "Technical Diving",
      "Technical Scuba Diving Text": "This is one of the first step to go beyond recreational diving",
      "Full Cave Scuba Diving Title": "Full Cave Diving",
      "Dive Master Title": "Dive Master",
      "About Us Subtitle": "ABOUT US",
      "About Us Title": `Travel with \n professionals`,
      "About Us Subtitle": "Easy Chango has operated since 2008.",
      "We also have": "We also have",
      "Open Water Diver Title": "Open Water Diver",
      "Advanced Open Water Diver Title": "Advanced Open Water Diver",
      "Rescue Diver Title": "Rescue Diver",

      // Courses
      "EFR Title": "EFR",
      "Dive Master Tile": "Dive Master",
      "T.D.I. Courses Title": "T.D.I. Courses",
      "Technical Diving Title": "Technical Diving",
      //Footer
      "Footer Diving Tours": "Diving Tours",
      "Footer Courses": "Courses",
      "Footer Tank Filling": "Fill Station",
      "Footer Filling Prices": "Prices",
      "Footer Follow Us": "Follow Us"
    },
  },
  es: {
    translation: {
      // Title
      "Icons Title": "Asociados a",
      "Courses Title": "Cursos que ofrecemos",
      callUsDetails: "Llámanos / envianos un mensaje para mayor detalles!",
      "Fill Tank": "Llenado de tanques",
      "Contact Title": "Comunícate con nosotros",
      contact: "Contacto",
      "Contact subtitle": "Y experimenta una de las mejores experiencias en Tulum",
      //Tours
      "Snorkel Tours": "Renta de Equipo",
      // buttons
      ButtonRead: "Más Información",
      ButtonBook: "Reserva Ahora",
      ButtonContact: "Contáctanos",

      // explore
      "Bull Shark Title": "Tiburón Toro",
      "Ocean Diving Title": "Nocturno",
      "Cenote Diving Title": "Buceo en Cenote",

      "Explore Now": "Exploremos",
      "Explore Title": "Bucea",
      "Explore subtitle":
        "Ven y disfruta de una bella experiencia bajo el agua",
      "Cave Diving Title": "Buceo de Cueva",
      "Cave Diving Text":
        "En la peninsula de Yucatán existen algunos de los buceos en caverna más hermosos del mundo. Formaciones coralinas a lo largo de kilómetro y medio de caliza.",
      "About Us": "NOSOTROS",
      "About Us Title": "Bucea con profesionales",
      "About Us Subtitle": 'Easy Chango ha estado llevando personas de todo el mundo a bucear desde 2008.',
      "We also have": "También tenemos",
      "Diving Tour Blue Subtitle": "Exploremos",
      "Diving Tours": "Buceo que ofrecemos",

      "Bull Shark Text":
        "Desde noviembre hasta marzo se pueden ver las hembras de tiburón toro en las costas de Playa del Carmen dándonos la oportunidad de conocerlas de cerca.",
      "Ocean Diving Text":
        "En la riviera Maya se encuentra la segunda barrera de coral más grande del mundo desde el norte de Cancún hasta Honduras",
      "Deep Dive Title": "Buceo Profundo",
      "Deep Dive Text":
        "Con el certificado de avanzado puedes bucear en los sink hole, Tulúm ofrece dos cenotes profundos, los más hermosos que existen. Profundidad máxima 30m/100ft",
      "Cavern Diving Title": "Buceo en Caverna",
      "Cavern Diving Text":
        "En el buceo de Cavernas se pueden apreciar las formaciones tales como las estalactitas, estalagmitas columnas, así como tambien los diferentes tonos de azul resultado de la Luz del sol penetrando sus tranquilas aguas.",
      "Coral Reef Title": "Buceo de arrecife",
      "Coral Reef Text":
        "Bucea los arrecifes coralinos frente a Tulúm. Bucearemos en las aguas del mar caribe con una temperatura de 26º/78f observando peces de colores y corales que forman un increíble paisaje.",
      "Whale Shark Title": "Nado con Tiburón Ballena",
      "Whale Shark Text":
        "Tiburones ballenas arriban a la Riviera Maya entre Isla Mujeres y Holbox entre junio y septiembre de cada año, es una gran oportunidad para avistar al pez mas grande del mundo. No te lo pierdas!",
      "Cenote Snorkeling Title": "Cenote Snorkeling",
      "Cenote Snorkeling Text":
        "Los cenotes (Tz'not) son la entrada a los ríos subterráneos más grandes del mundo. Conoce el inframundo maya nadando en sus aguas",
      "Discover Scuba Diving Title": "Tu primer buceo",
      "Discover Scuba Diving Text":
        "En este programa te ayudamos a disfrutar de tu primera experiencia con scuba diving. En nuestra tienda prepararemos todo el equipo SCUBA. Tu Divemaster/instructor impartirá una explicación básica de como usar el equipo SCUBA apropiadamente e importantes habilidades para hacer de tu primer buceo una experiencia divertida y segura. La clase dura alrededor de media hora, luego practicaras las habilidades a 5 m de profundidad y vámonos!",
      //Footer
      "Footer Diving Tours": "Salidas",
      "Footer Courses": "Cursos",
      "Footer Tank Filling": "Llenado de Tanques",
      "Footer Filling Prices": "Precios",
      "Footer Follow Us": "Siguenos"
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getNavigatorLanguage(),
    fallbackLng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
