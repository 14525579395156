import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { tours } from "../../data";
import DivingTour from "../DivingTour";
import { useTranslation } from "react-i18next";
import { Texture } from '@material-ui/icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    divingToursContainer: {
      display: "flex",
      overflowX: "scroll",
      position: 'relative',
      zIndex: 10,
    },
    title: {
      margin: `${theme.spacing(5)}px 0`
    },
    paperRoot: {
      ...theme.mixins.section,
      position: 'relative',
    },
    texture: {
      position: 'absolute',
      bottom: 0,
      left: theme.spacing(-35),
      zIndex: 0,
    },
    carousel: {
      borderRadius: 0,
      position: 'relative',
      overflowX: 'hidden',
    }
  })
);

export default function DivingTours() {
  const { t } = useTranslation();
  const classes = useStyles();
  // return null;
  return (
    <>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography classes={{ root: classes.title }} variant="h5">{t("Diving Tours")}</Typography>
      </Paper>
       <Paper classes={{root: classes.carousel}}>
         <div className={classes.divingToursContainer}>
          {tours.map((tour, idx) => (
            <DivingTour key={idx} {...tour} />
          ))}
        </div>
         <Hidden mdUp>
          <img alt="" src="/images/texture.svg" className={classes.texture} />
        </Hidden>
      </Paper> 
    </>
  );
}
