import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './themes';
import LandingPage from './components/LandingPage';
import Service from './components/Service';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Router>
          <Switch>
            <Route path="/bullshark" >
              <Service type={"Bull Shark Diving"} price={"$150"} />
            </Route>
            <Route path="/ocean_diving" ><Service type={"Ocean Diving"} /></Route>
            <Route path="/cave_diving" ><Service type={"Cave Diving"} /></Route>
            <Route path="/" >
              <LandingPage />
            </Route>
          </Switch>
        </Router>
      </div >
    </ThemeProvider>
  );
}

export default App;
