import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowLeft from '@material-ui/icons/ArrowBackIos';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Header from '../Header';
import ActionButton from '../ActionButton';
import { ReactComponent as FacebookIcon } from '../../ic_face.svg';
import { ReactComponent as InstagramIcon } from '../../ic_instagram.svg';
import { ReactComponent as TwitterIcon } from '../../ic_twitter.svg';
import { useTranslation } from "react-i18next";
import classNames from 'classnames';


const useStyles = makeStyles((theme) =>
    createStyles({
        paperRoot: {
            ...theme.mixins.section,
            marginTop: theme.spacing(20),
        },
        price: {
            color: theme.palette.primary.main,
        },
        share: {
            color: theme.palette.inactive.main,
            "& svg path": {
                fill: theme.palette.inactive.main,
            }
        },
        home: {
            color: 'inherit',
            textDecoration: 'none',
            marginBottom: theme.spacing(6),
        },
        poster: {
            borderRadius: theme.shape.borderRadius,
            objectFit: 'cover',
            width: '100%',
            height: '100%'
        },
        thubmnail: {
            borderRadius: theme.shape.borderRadius,
            objectFit: 'cover',
            width: '4.5em',
            height: '4.5em',
            filter: 'grayscale(100%)',
            opacity: '.5',
            margin: theme.spacing(.8),
            marginLeft: 0,
        },
        activeThumbnail: {
            opacity: 1,
            filter: 'none',
        },
        texture: {
            position: 'relative',
            left: theme.spacing(-15),
            top: theme.spacing(3),
        }
    }));

export default function Service({ type, price }) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Header />
            <Paper classes={{ root: classes.paperRoot }}>
                <Grid container>
                    <Link to="/" className={classes.home}>
                        <Grid item container direction="row" alignItems="center">
                            <ArrowLeft />
                            <Typography variant="h6">{t("backHome")}</Typography>
                        </Grid>
                    </Link>
                    <Grid item container direction="row" alignItems="center">
                        <Grid item container xs={6}>
                            <Grid item container xs={2}>
                                <img className={classes.thubmnail} src="/images/cavern.png" />
                                <img className={
                                    classNames(classes.thubmnail, classes.activeThumbnail)}
                                    src="/images/bullshark-3 1.png" />
                                <img className={classes.thubmnail} src="/images/deepDive.png" />
                                <img className={classes.thubmnail} src="/images/fullcave.png" />
                                <img className={classes.thubmnail} src="/images/discoverScuba.png" />
                                <img className={classes.thubmnail} src="/images/whale.jpeg" />
                            </Grid>
                            <Grid item xs={9}>
                                <img className={classes.poster} src="/images/bullshark-3 1.png" />
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" xs={6} spacing={3}>
                            <Grid item>
                                <Typography variant="h5">{t(type)}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h6" classes={{ root: classes.price }}>{price}</Typography>
                            </Grid>
                            <Grid item><Typography variant="body1">From November through March, pregnant female Bull Sharks come near to Playa del Carmen, giving divers a great opportunity to meet them, the female bull sharks are calm and they let us get close without the need of any special protection...</Typography></Grid>
                            <Grid item><Typography variant="overline">Read more</Typography></Grid>
                            <Grid item container justify="space-around">
                                <ActionButton>
                                    <Typography>Pay with Paypal</Typography>
                                </ActionButton>
                                <ActionButton>{t("Add to cart")}</ActionButton>
                            </Grid>
                            <Grid
                                item
                                container
                                classes={{ root: classes.share }}
                                spacing={2}
                                alignItems="center">
                                <Grid item>
                                    <Typography variant="subtitle2">Share</Typography>
                                </Grid>
                                <Grid item><FacebookIcon /></Grid>
                                <Grid item><InstagramIcon /></Grid>
                                <Grid item><TwitterIcon /></Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <img className={classes.texture} src="/images/texture.svg" />
            </Paper>
        </>
    );
}