import { useState, useCallback } from 'react';
import ActionButton from '../ActionButton/index.js';
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from "@material-ui/core/Link";
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as FacebookIcon } from '../../ic_face.svg';
import { ReactComponent as InstagramIcon } from '../../ic_instagram.svg';
import { ReactComponent as TwitterIcon } from '../../ic_twitter.svg';

import { useTranslation } from "react-i18next";
import ExploreOptions from './exploreOptions';

import { explore } from '../../data';

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            maxWidth: "500px",
            [theme.breakpoints.down('sm')]: {
                maxWidth: "400px",
                color: theme.palette.secondary.main
            }
        },
        subTitle: {
            margin: '1.5em 0',
            [theme.breakpoints.down('sm')]: {
                color: theme.palette.secondary.main
            }
        },
        group: {
            paddingTop: theme.spacing(6),
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(10),
            }
        },
        socialIcons: {
            zIndex: 100,
            '& path': {
                fill: theme.palette.secondary.main
            }
        },
        paperRoot: {
            ...theme.mixins.section,
            position: 'relative',
            height: 632,
            [theme.breakpoints.down('sm')]: {
                paddingLeft: theme.spacing(2)
            }
        },
        image: {
            position: 'absolute',
            zIndex: 0,
            objectFit: "cover",
            top: 0,
            right: 0,
            left: '17em',
            borderRadius: "10px 0 0 10px",
            background: `no-repeat local top left`,
            [theme.breakpoints.down('sm')]: {
                background: `no-repeat local top left;`,
                left: 0,
                padding: 0,
                borderRadius: 0,
            }
        }
    })
);

export default function Explore({ options = explore }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [active, setActive] = useState(0);
    const data = options[active];
    const theme = useTheme();

    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    const ExploreVideo = useCallback(() => <video
        className={classes.image}
        autoPlay
        loop
        muted
        width="1062"
        height="632"
        poster={data.imageUrl}>
        <source src={data.videoUrl} type="video/mp4" />
    </video>, [data.videoUrl]
    )
    return (
        <>
            <Paper classes={{ root: classes.paperRoot }} >
                <ExploreVideo />
                <Grid container direction={smDown ? "column" : "row"} alignContent="center" style={{ height: '100%' }} justify={smDown ? "space-around" : "space-between"}>
                    <Grid item className={classes.group} md={12} style={{ zIndex: 100 }}>
                        <Typography variant="overline">{t("Explore Title")}</Typography>
                        <Typography variant={smDown ? "h2" : "h1"} className={classes.title}>{t(data.label)}</Typography>
                        <Typography variant={smDown ? "body1" : "subtitle1"} className={classes.subTitle}>{t("Explore subtitle")}</Typography>
                        <ActionButton>{t("ButtonBook")}</ActionButton>
                    </Grid>
                    <Grid
                        item
                        container
                        direction={smDown ? "row" : "column"}
                        alignContent="flex-end"
                        justify={smDown ? "flex-start" : "flex-end"}
                        classes={{ root: classes.socialIcons }}
                        md={11}
                    >
                        <Grid item xs={1}>
                            <Link href={t("facebookLink")} target="_blank">
                                <SvgIcon component={FacebookIcon} />
                            </Link>
                        </Grid>
                        <Grid item xs={1}><SvgIcon component={InstagramIcon} /></Grid>
                        <Grid item xs={1}><SvgIcon component={TwitterIcon} /></Grid>
                    </Grid>
                </Grid>
            </Paper>
            <ExploreOptions active={active} onSelect={setActive} options={options} />
        </>
    );
}