import ActionButton from '../ActionButton/index.js';
import { useTranslation } from "react-i18next";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Link from "@material-ui/core/Link";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) =>
    createStyles({
        aboutUsImage: {
            width: '100%',
            height: "445.18px",
            objectFit: 'cover',
            borderRadius: theme.shape.borderRadius,
        },
        partners: {
            backgroundColor: '#fff',
            borderRadius: theme.shape.borderRadius,
            color: '#999',
            fontWeight: 'bold',
            padding: theme.spacing(2),
            '& img': {
                width: 75,
                height: 75,
            },
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                '& .MuiGrid-item': {
                    marginBottom: theme.spacing(2),
                    marginTop: theme.spacing(2)
                }
            }
        },
        paperRoot: {
            ...theme.mixins.section,
            marginTop: '5em',
            paddingRight: '3em'
        },
        logo: {
            position: 'absolute',
            right: '-18em',
            transform: 'rotate(-30deg)'
        },
        texture: {
            position: 'absolute',
            left: theme.spacing(-30),
            bottom: theme.spacing(-2),
            zIndex: -1,
        },
        imageWrapper: {
            position: 'relative',
        },
        text: {
            '& .MuiTypography-overline': {
                [theme.breakpoints.down('sm')]: {
                    fontSize: 20,
                }
            }
        },
        partnerInstitutionsTitle: {
            color: theme.palette.gray.light,
            fontWeight: 500,
            marginBottom: theme.spacing(3),
        }
    })
);

export default function AboutUs() {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Paper classes={{ root: classes.paperRoot }} >
                <Grid container direction="column">
                    <Grid item container wrap="nowrap" style={{ position: 'relative' }} direction={smDown ? 'column-reverse' : 'row'} spacing={smDown ? 8 : 5}>
                        <Grid item xs={12} md={6} classes={{ root: classes.imageWrapper }}>
                            <img src="/images/texture.svg" className={classes.texture} />
                            <img src="/images/about.png" className={classes.aboutUsImage} />
                        </Grid>
                        <Grid item container direction="column" xs={6} spacing={1} justify="center" classes={{ root: classes.text }}>
                            <Grid item>
                                <Typography variant="overline">{t("About Us")}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={smDown ? "h4" : "h5"}>{t("About Us Title")}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography style={{ maxWidth: "80%" }}> {t("About Us Subtitle")}</Typography>
                            </Grid>
                            <Grid item>
                                <ActionButton>{t("ButtonRead")}</ActionButton>
                            </Grid>
                        </Grid>
                        <Hidden smDown>
                            <img className={classes.logo} src="/images/logo-blue-big-outline.png" />
                        </Hidden>
                    </Grid>
                </Grid>
            </Paper>
            <Paper classes={{ root: classes.paperRoot }}>
                <Hidden mdUp>
                    <Typography variant="h6" classes={{ root: classes.partnerInstitutionsTitle }}>Partner Institutions</Typography>
                </Hidden>
                <Grid container alignItems="center" justify={smDown ? "space-between" : "space-around"} classes={{ root: classes.partners }}>
                    <Hidden smDown>
                        <Typography variant="inherit">{t("Icons Title")}</Typography>
                    </Hidden>
                    <Grid item xs={4} sm={2} md={1}>
                        <Link href={t("iatdLink")} target="_blank">
                            <img src={"/images/iatd.png"} />
                        </Link>
                    </Grid>
                    <Grid item xs={4} sm={2} md={1}>
                        <Link href={t("padiLink")} target="_blank">
                            <img src={"/images/padi.png"} />
                        </Link>
                    </Grid>
                    <Grid item xs={4} sm={2} md={1}>
                        <Link href={t("tdiLink")} target="_blank">
                            <img src={"/images/tdi.png"} />
                        </Link>
                    </Grid>
                    <Grid item xs={4} sm={2} md={1}>
                        <Link href={t("nacdLink")} target="_blank">
                            <img src={"/images/nacd.png"} />
                        </Link>
                    </Grid>
                    <Grid item xs={4} sm={2} md={1}>
                        <Link href={t("protecLink")} target="_blank">
                            <img src={"/images/protec.png"} />
                        </Link>
                    </Grid>
                    <Grid item xs={4} sm={2} md={1}>
                        <Link href={t("astdLink")} target="_blank">
                            <img src={"/images/astd.png"} />
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}