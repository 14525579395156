import Paper from "@material-ui/core/Paper";
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Option from './option';

const useStyles = makeStyles((theme) =>
    createStyles({
        tabs: {
            userSelect: 'none',
            display: "flex",
            '&>div': {
                width: '35%',
            },
            marginBottom: '2em'
        },
        inactive: {
            color: theme.palette.inactive.main,
            '& .imgWrapper': {
                borderBottomColor: theme.palette.inactive.main,
            }
        },
        texture: {
            display: 'block',
            margin: "0 0 0 auto"
        },
        paperRoot: {
            ...theme.mixins.section
        }
    })
);

export default function ExploreOptions({ active = 0, onSelect = () => { }, options = [] }) {
    const classes = useStyles();
    return (
        <Paper classes={{ root: classes.paperRoot }}>
            <div className={classes.tabs}>
                {
                    options.map(({ icon, label }, idx) => (
                        <Option
                            key={label}
                            onClick={() => onSelect(idx)}
                            active={active === idx}
                            label={label}
                            src={active === idx ? icon.active : icon.inactive}
                        />
                    ))
                }
            </div>
            <Hidden smDown>
                <img alt="" src="/images/texture.svg" className={classes.texture} />
            </Hidden>
        </Paper >
    )
}