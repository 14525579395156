import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            position: "relative",
            display: 'flex',
            flexDirection: 'column',
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            [theme.breakpoints.up('md')]: {
                '& .explore': {
                    height: '0',
                    overflow: 'hidden',
                    transition: 'all .1s linear',
                    color: theme.palette.secondary.main,
                },
                '&:hover .explore': {
                    height: '1.5em'
                }
            }
        },
        image: {
            objectFit: 'cover',
            height: "365px",
            width: "263px",
            borderRadius: "10px",
        },
        textBox: {
            position: "absolute",
            display: 'flex',
            flexDirection: 'column',
            bottom: theme.spacing(2),
            left: 0,
            right: 0,
            margin: 0,
            flex: '1 1',
            [theme.breakpoints.up('md')]: {
                textAlign: "center",
            },
            [theme.breakpoints.down('sm')]: {
                position: 'static',
            },

        },
        title: {
            color: theme.palette.secondary.main,
            flex: '1 1',
            width: '100%',
            [theme.breakpoints.up('md')]: {
                fontSize: 16,

            },
            [theme.breakpoints.down('sm')]: {

                position: 'static',
                color: 'inherit',
                fontWeight: 600,
                marginTop: theme.spacing(2),
            }
        },
    }))

export default function Courses({ imageURL, title }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Paper className={classes.container}>
            <img className={classes.image} src={imageURL} />
            <Paper classes={{ root: classes.textBox }}>
                <Typography classes={{ root: classes.title }} variant={smDown ? "h5" : "h6"} dangerouslySetInnerHTML={{ __html: t(title) }} />
                <Typography classes={{ root: 'explore' }} variant={smDown ? "overline" : "body1"}>{t("Explore Now")}</Typography>
            </Paper>
        </Paper>
    )
}